import React from 'react'
import { Link } from 'react-router-dom'
import dogs from "./dogs.jpg"

const PortfolioContent = () => {
  return (
    <div>
        <div className="container">
            <div className="row">
                <div className="col-lg-6 my-5"> 
                <Link to={`${process.env.PUBLIC_URL + "/prostate_mri_cancer_detection_ai_engine"}`}>
                    <img src="https://developer-blogs.nvidia.com/wp-content/uploads/2019/08/prostate_Detection.png" className='img-fluid d-block mx-auto portfolio-cards-img rounded-3' alt="" />
                <h3 className='text-center text-primary mt-3'>Prostate MRI Cancer Detection AI Engine</h3>
                </Link>
                </div>
                <div className="col-lg-6 my-5"> 
                <Link to={`${process.env.PUBLIC_URL + "/heart_disease_detection"}`}>
                    <img src="https://www.nsmedicaldevices.com/wp-content/uploads/sites/2/2021/06/heart-image-.jpg" className='img-fluid d-block mx-auto portfolio-cards-img' alt="" />
                <h3 className='text-center text-primary mt-3'>Heart Disease Detection</h3>
                </Link>
                </div>
                <div className="col-lg-6 my-5"> 
                <Link to={`${process.env.PUBLIC_URL + "/text_extraction"}`}>
                    <img src="https://media.springernature.com/lw685/springer-static/image/art%3A10.1007%2Fs42979-022-01159-0/MediaObjects/42979_2022_1159_Fig1_HTML.png" className='img-fluid d-block mx-auto portfolio-cards-img' alt="" />
                <h3 className='text-center text-primary mt-3'>Text Extraction system based on hand-drawn circuit diagrams.</h3>
                </Link>
                </div>
                <div className="col-lg-6 my-5"> 
                <Link to={`${process.env.PUBLIC_URL + "/dogs_breed_detection_system"}`}>
                
                    <img src={dogs} className='img-fluid d-block mx-auto portfolio-cards-img rounded-3' alt="" />
                <h3 className='text-center text-primary mt-3'>Dogs Breed Detection system</h3>
                </Link>
                </div>
            </div>
        </div>
      
    </div>
  )
}

export default PortfolioContent
