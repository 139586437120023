import React from "react";
import LocationMap from "../../../components/contact/contact-map";
import styles from "./ContactMap.module.scss";
import "./hs.css";
import { IoLogoWhatsapp } from "react-icons/io";
import { BsPhone } from "react-icons/bs";

// BsFillTelephoneFill

const ContactMap = () => {
  const handleClick = () => {
    window.open(
      "https://wa.me/19093513911?text=Referral%20link%20website%3A%20www.sasstechnologies.com",
      "_blank"
    );
  };
  const handleClick1 = () => {
    window.open(
      "https://wa.me/447448782022?text=Referral%20link%20website%3A%20www.intelliverse.ca",
      "_blank"
    );
  };
  const handleClick2 = () => {
    window.open("https://wa.link/q1w8ze", "_blank");
  };
  return (
    <div className={`${styles.voopoAddress} bg--cart-10`}>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-7 col-lg-5">
            <div className={styles.vpContactAddress}>
              <h2>Out Contact Info</h2>
              <div className="vp__address__container">
                {/* Start Single Address */}
                <div className={styles.vpAddress}>
                  <h4>Address</h4>
                  <div className="">
                    <h5>USA</h5>
                    <p className="m-0">
                      1172 Northwest highway Dallas tx 75220{" "}
                    </p>
                  </div>
                  <div className=" mt-4">
                    <h5>UK</h5>
                    <p className="m-0">
                      122 Harold Rd, London E13 0SF, United Kingdom{" "}
                    </p>
                  </div>
                </div>
                {/* End Single Address */}
                {/* Start Single Address */}
                <div className={styles.vpAddress}>
                  <h4>Email</h4>
                  <p>
                    <a href="mailto:Info@intelliverse.ca">
                      Info@intelliverse.ca
                    </a>
                  </p>
                </div>
                {/* End Single Address */}
                {/* Start Single Address */}
                <div className={styles.vpAddress}>
                  <h4>Phone</h4>
                  <h5>Available on Call & WhatsApp</h5>

                  <div className="d-flex align-items-center">
                    <IoLogoWhatsapp className="whatsaps" />
                    <div className="w-100 ms-2">
                      <p onClick={() => handleClick()} className="hc">
                        USA +1 909 351 3911
                      </p>
                      <p onClick={() => handleClick1()} className="hc">
                        UK +44 7448 782022
                      </p>
                      <p onClick={() => handleClick2()} className="hc">
                        EU +49 1521 6652405
                      </p>
                    </div>
                  </div>
                </div>
                <div className={styles.vpAddress}>
                  <h5>Available on Call</h5>

                  <div className="d-flex align-items-center">
                    <BsPhone className="whatsaps text-secondary" />

                    <div className="w-100 ms-2">
                    
                      <p>
                        <a href="tel:+1(281) 712-8941">USA +1(281) 712-8941</a>
                      </p>
                    </div>
                  </div>
                </div>
                {/* End Single Address */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.googleMap}>
        {/* location map */}
        <LocationMap latitude="32.8629507" longitude="-96.6452146" />
      </div>
    </div>
  );
};

export default ContactMap;
