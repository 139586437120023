import React from "react";

const Heart = () => {
  return (
    <div>
      <div className="container my-5">
        <div className="row">
        <img src="https://www.nsmedicaldevices.com/wp-content/uploads/sites/2/2021/06/heart-image-.jpg" className='img-fluid d-block mx-auto portfolio-cards-img' alt="" />

          <h1 className="text-center text-primary my-3">
          Heart Disease Detection
          </h1>
<div className="col-lg-8 mx-auto">
          <h6>
          Heart Disease Detection portfolio demonstrates a state-of-the-art solution that leverages AI and advanced image analysis to revolutionize the detection and management of heart diseases. With its accurate and efficient analysis of echocardiogram and echocardiography images, our system empowers healthcare professionals to make timely and informed decisions, leading to improved patient outcomes and quality of care.
            <br />
            <br />
            Our AI algorithms have been meticulously trained on large datasets of annotated echocardiogram and echocardiography images, encompassing diverse patient populations and cardiac conditions. This extensive training enables our system to accurately interpret and analyze images, delivering reliable results that support clinical decision-making.
          </h6>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Heart;
