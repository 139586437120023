import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts";
import Breadcrumb from "../components/UI/breadcrumb";
import AIEngine from "../components/portfolio/AIEngine"


const AIProtfolio = () => {
  return (
    <Fragment>
      <MetaTags>
        <title>Intelliverse | AIProtfolio</title>
        <meta
          name="description"
        />
      </MetaTags>
      <LayoutOne>
        {/* breadcrumb */}
        <Breadcrumb title="Portfolio : Prostate MRI Cancer Detection AI Engine" />
        {/* post details with sidebar */}
        <AIEngine />
      </LayoutOne>
    </Fragment>
  );
};

export default AIProtfolio;
