import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts";
import Breadcrumb from "../components/UI/breadcrumb";
import TextExtraction from "../components/portfolio/TextExtraction"


const TextPortfolio = () => {
  return (
    <Fragment>
      <MetaTags>
        <title>Intelliverse | TextPortfolio</title>
        <meta
          name="description"
        />
      </MetaTags>
      <LayoutOne>
        {/* breadcrumb */}
        <Breadcrumb title="Portfolio : Text Extraction system based on hand-drawn circuit diagrams." />
        {/* post details with sidebar */}
        <TextExtraction />
      </LayoutOne>
    </Fragment>
  );
};

export default TextPortfolio;
