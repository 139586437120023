import React from "react";

const AIEngine = () => {
  return (
    <div>
      <div className="container my-5">
        <div className="row">
        <img src="https://developer-blogs.nvidia.com/wp-content/uploads/2019/08/prostate_Detection.png" className='img-fluid d-block mx-auto portfolio-cards-img' alt="" />

          <h1 className="text-center text-primary my-3">
          Prostate MRI Cancer Detection AI Engine
          </h1>
<div className="col-lg-8 mx-auto">
          <h6>
          Prostate MRI Cancer Detection AI Engine portfolio highlights a groundbreaking solution that combines the power of AI and medical imaging to transform prostate cancer diagnosis. With its high accuracy, efficiency, and seamless integration, our AI engine empowers radiologists to make more informed decisions, improving patient care and ultimately saving lives.

            <br />
            <br />
            Our AI engine has been meticulously developed by a team of expert researchers, radiologists, and data scientists, combining their knowledge of medical imaging with the latest advancements in machine learning algorithms. The result is a highly accurate and efficient tool that aids in the early detection and characterization of prostate cancer through MRI scans.
          </h6>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AIEngine;
