import React from "react";
import axios from "axios";
import swal from "sweetalert";
import Button from "../../../components/UI/button";
import { useForm } from "react-hook-form";
import styles from "./ContactForm.module.scss";
import contactThumb from "../../../assets/images/about/contact.png";

const ContactForm = () => {
  const { register, handleSubmit, reset } = useForm();
  const [isLoading,setIsLoading]=React.useState(false)
  const onSubmit = async (data) => {
    setIsLoading(true)
    try {
      const response = await axios.post(
        "http://assignmentapi.dev-nuh.xyz/api/sent/email",
        data
      );
      swal(response.data.message, "", "success");
      reset();
      setIsLoading(false)
      console.log(response.data.message);
    } catch (error) {
      setIsLoading(false)
      console.log(error);
    }
  };

  return (
    <div className="voopo__contact ptb--110">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6">
            <div className={styles.voopoContactForm}>
              <h2>
                If you need to contact us, please fill out the form below.
              </h2>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className={styles.singleContactForm}>
                  <input
                    type="text"
                    name="name"
                    placeholder="Type Your Name"
                    {...register("name", { required: true })}
                  />
                </div>
                <div className={styles.singleContactForm}>
                  <input
                    type="email"
                    name="email"
                    placeholder="Type Your e-mail"
                    {...register("email", { required: true })}
                  />
                </div>
                <div className={styles.singleContactForm}>
                  <input
                    type="number"
                    name="phone_no"
                    placeholder="Type Your Phone Number"
                    {...register("phone_no", { required: true })}
                  />
                </div>
                <div className={`${styles.singleContactForm} message`}>
                  <textarea
                    name="message"
                    placeholder="Type Your Message"
                    defaultValue={""}
                    {...register("message", { required: true })}
                  />
                </div>
                <div className={styles.contactBtn}>
                  {/* <button type="submit">submits</button> */}
                  {/* <Button text="Submit" /> */}
                  <div className="d-flex align-items-center">
                    <Button type="button" text="Send" />
                    {isLoading && <div class="spinner-border ms-4" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>}
                    {/* <input type="submit" className="send-btns" value="Send" /> */}
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-5 offset-lg-1">
            <div className={styles.contactThumb}>
              <img src={contactThumb} alt="voopo voip" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
