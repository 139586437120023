import React from "react";
import Swiper from "react-id-swiper";
import sliderData from "../../../data/hero-sliders/hero-slider-one.json";
import HeroSliderOneSingle from "../../../components/hero-sliders/hero-slider-one";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import styles from "./HeroSliderOne.module.scss";
import Particle from "../../../components/particles/Particle";
const HeroSliderOne = () => {
  const params = {
    effect: "fade",
    loop: false,
    speed: 1000,
    autoplay: false,
    watchSlidesVisibility: true,
    navigation: false,
    pagination: false,
    renderPrevButton: () => (
      <button
        className={`${styles.swiperButtonPrev} ${styles.htSwiperButtonNav} swiper-button-prev`}
      >
        <IoIosArrowBack />
      </button>
    ),
    renderNextButton: () => (
      <button
        className={`${styles.swiperButtonNext} ${styles.htSwiperButtonNav} swiper-button-next`}
      >
        <IoIosArrowForward />
      </button>
    ),
  };
  return (
    <>
      <div className={`hero-slider ${styles.heroSlider} ${styles.sliderOne}`}>
        <div className="slideActivation">
          <Swiper {...params}>
            {sliderData &&
              sliderData.map((single, key) => (
                <div className="swiper-slide" key={key}>
                  <HeroSliderOneSingle
                    data={single}
                    key={key}
                    styles={styles}
                    sliderClassName="swiper-slide"
                  />
                </div>
              ))}
            <Particle />
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default HeroSliderOne;
