import React from "react";
import serviceImage from "../../../assets/images/bg/2.jpg";
import ServiceSidebar from "../service-sidebar";

const ServiceDetailsOne = ({ sidebarPosition }) => {
  return (
    <div className="page-wrapper ptb--110">
      {/*Service section start*/}
      <div className="service-section">
        <div className="container">
          <div className="row">
            <div
              className={`col-lg-8 col-12 ${
                sidebarPosition && sidebarPosition === "left"
                  ? "order-1 order-lg-2"
                  : ""
              }`}
            >
              <div className="service-details">
                {/* service gallery */}
                <div className="service-gallery">
                  <img src={serviceImage} className="img-fluid" alt="" />
                </div>

                <div className="content pt--30">
                  <div className="row">
                    <div className="col-12">
                      <h2>AI development</h2>
                      <p>
                      AI development refers to the process of creating, training, and deploying artificial intelligence systems and algorithms. It involves building and refining models that can learn from data, make predictions, and perform tasks that typically require human intelligence. 
                      </p>
                     
                    </div>
                    <div className="col-lg-6 col-12 pt--30">
                      <h3>Data strategy & Digital Transformation</h3>
                      <p>
                      Data strategy refers to a comprehensive plan that outlines how an organization will acquire, manage, analyze, and utilize data to achieve its business objectives. A well-defined data strategy helps organizations harness the value of their data assets and make informed decisions based on insights derived from data.
                      </p>
                    </div>
                    <div className="col-lg-6 col-12 pt--30">
                      <h3>Bespoke software development</h3>
                      <p>
                      Bespoke software development, also known as custom software development, refers to the process of creating software solutions that are tailored to meet the specific needs and requirements of an organization or business. Unlike off-the-shelf software that is pre-built and available for general use, bespoke software is designed and developed from scratch to address unique business challenges and objectives.
                      </p>
                    </div>
                    <div className="col-lg-6 col-12 pt--30">
                      <h3>Service Planning</h3>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Fugiat, animi? Vel quas in minima qui totam,
                        aliquid dolores quaerat voluptatum?
                      </p>
                    </div>
                    <div className="col-lg-6 col-12 pt--30">
                      <h3>Natural Language Processing</h3>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Fugiat, animi? Vel quas in minima qui totam,
                        aliquid dolores quaerat voluptatum?
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`col-lg-4 col-12 ${
                sidebarPosition && sidebarPosition === "left"
                  ? "order-2 order-lg-1"
                  : ""
              }`}
            >
              {/* service sidebar */}
              <ServiceSidebar />
            </div>
          </div>
        </div>
      </div>
      {/*Service section end*/}
    </div>
  );
};

export default ServiceDetailsOne;
