import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts";
import Breadcrumb from "../components/UI/breadcrumb";
import Dog from "../components/portfolio/Dog";


const DogsPortfolio = () => {
  return (
    <Fragment>
      <MetaTags>
        <title>Intelliverse | DogsPortfolio</title>
        <meta
          name="description"
        />
      </MetaTags>
      <LayoutOne>
        {/* breadcrumb */}
        <Breadcrumb title="Portfolio : Dogs Breed Detection system" />
        {/* post details with sidebar */}
        <Dog />
      </LayoutOne>
    </Fragment>
  );
};

export default DogsPortfolio;
