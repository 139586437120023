import React from "react";
import dogs from "./dogs.jpg"

const Dog = () => {
  return (
    <div>
      <div className="container my-5">
        <div className="row">
          <img
            src={dogs}
            className="img-fluid d-block mx-auto portfolio-cards-img"
            alt=""
          />

          <h1 className="text-center text-primary my-3">
            Dogs Breed Detection system
          </h1>
<div className="col-lg-8 mx-auto">
          <h6>
            Dogs Breed Detection system, which integrates information about
            breed identification, diseases, training, and treatment. Our
            advanced technology combines machine learning algorithms and a vast
            database of canine information to provide valuable insights and
            support for dog owners, breeders, veterinarians, and enthusiasts.
            <br />
            <br />
            Our portfolio highlights the remarkable capabilities of our Dogs
            Breed Detection system, which accurately identifies the breed of a
            dog based on various visual characteristics and features. By
            leveraging advanced image recognition techniques and extensive breed
            databases, our system offers quick and reliable breed
            identification, enabling dog owners and professionals to better
            understand their furry companions.
          </h6>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dog;
