import React from "react";

const TextExtraction = () => {
  return (
    <div>
      <div className="container my-5">
        <div className="row">
          <img
            src="https://media.springernature.com/lw685/springer-static/image/art%3A10.1007%2Fs42979-022-01159-0/MediaObjects/42979_2022_1159_Fig1_HTML.png"
            className="img-fluid d-block mx-auto portfolio-cards-img"
            alt=""
          />

          <h1 className="text-center text-primary my-3">
            Text Extraction system based on hand-drawn circuit diagrams.
          </h1>
          <div className="col-lg-8 mx-auto">
            <h6>
              Text Extraction system based on hand-drawn circuit diagrams. Our
              cutting-edge technology leverages advanced image processing
              techniques and machine learning algorithms to extract textual
              information from hand-drawn circuit diagrams accurately and
              efficiently.
              <br />
              <br />
              Our portfolio highlights the remarkable capabilities of our Text
              Extraction system, which addresses the challenges posed by
              hand-drawn diagrams that lack the precision and uniformity of
              computer-generated schematics. By utilizing sophisticated
              algorithms, our system can analyze and interpret the diverse
              styles and variations present in hand-drawn circuit diagrams,
              extracting crucial textual data.
            </h6>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TextExtraction;
